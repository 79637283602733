import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { graphql } from 'gatsby'

import SEO from "../components/seo";
import Layout from "../components/layout";
import HeroHomeSlider from "../components/hero-home-slider";
import OpenLetter from "../components/open-letter";
import ZFlow from "../components/z-flow";

export default function Home({ path, data: { cms: { entry, seo } } }) {
  const [ themeLogo, setthemeLogo ] = useState('');
  function changeThemeLogo(theme) {
    setthemeLogo(theme);
  }
  return (
    <Layout theme="light" themeLogo={themeLogo}>
      <SEO
        title={seo.title}
        description={seo.og_description}
        meta={[{
          "property": "og:image",
          "content": "/images/og-updated.png"
        }]}
      />

      <Helmet>
        <script type="application/ld+json">
          {`
        {
          "@context": "https://schema.org",
          "@type": "Organization",
          "name": "SoftBank Opportunity Fund",
          "url": "https://theopportunityfund.com",
          "logo": "https://theopportunityfund.com/images/og-updated.png",
          "alternateName": "The SoftBank Opportunity Fund",
          "sameAs": [
            "https://www.linkedin.com/company/sb-opportunity-fund"
          ]
        }
        `}
        </script>
      </Helmet>

      <HeroHomeSlider
        slides={entry.slides}
        onchangeTheme={changeThemeLogo}
      />
      <OpenLetter
        date={entry.open_letter_date}
        heading={entry.open_letter_heading}
        body={entry.open_letter_body}
      />
      <ZFlow sections={entry.text_imagesections} />

    </Layout>
  );
}


export const query = graphql`
query home {
  cms {
    entry(id: "585686e8-3343-4026-a2ae-3339e35a3006") {
      ... on cms_Entry_Pages_PageHome {
        id
        title
        slides {
          heading
          copy
          cta
          cta_text
          cta_type {
            value
          }
          theme {
            value
          }
          cta_file {
            ... on cms_Asset_Assets {
              id
              path
              imageFile {
                publicURL
              }
            }
          }
          imageMeta
          layout {
            value
          }
          logo {
            path
            id
            ... on cms_Asset_Assets {
              id
              alt
              imageFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 1000
                    formats: [AUTO, WEBP]
                  )
                }
                absolutePath
              }
            }
          }
          asset {
            path
            id
            ... on cms_Asset_Assets {
              id
              alt
              imageFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 4000
                    formats: [AUTO, WEBP]
                  )
                }
              }
            }
          }
        }
        open_letter_heading
        open_letter_body
        open_letter_date(format: "m.d.y")
        text_imagesections {
          caption
          heading
          body
          cta_text
          cta_link
          image {
            id
            path
            ... on cms_Asset_Assets {
              id
              alt
              imageFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 4000
                    formats: [AUTO, WEBP]
                  )
                }
              }
            }
          }
        }
      }
    }
    portfolio_companies: entries(collection: "companies", sort: "order") {
      data {
        id
        slug
        ... on cms_Entry_Companies_Companies {
          id
          title
          founder_label
          founders
          company_url
        }
      }
    }
    seo: SeoMeta(page_id: "585686e8-3343-4026-a2ae-3339e35a3006") {
      og_description
      og_title
      title
    }
  }

}

`